import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export enum WhereToBoxButtons {
  FORWARD,
  BACK,
}

interface WhereToBoxStateInterface {
  buttons: {
    backDisabled: boolean;
    forwardDisabled: boolean;
  };
}

export const initialState: WhereToBoxStateInterface = {
  buttons: {
    backDisabled: false,
    forwardDisabled: false,
  },
};

export const whereToBoxSlice = createSlice({
  name: "whereToBox",
  initialState,
  reducers: {
    setEnable: (
      state,
      action: PayloadAction<{ disabled: boolean; button: WhereToBoxButtons }>,
    ) => {
      switch (action.payload.button) {
        case WhereToBoxButtons.FORWARD:
          state.buttons.forwardDisabled = action.payload.disabled;
          break;
        case WhereToBoxButtons.BACK:
          state.buttons.backDisabled = action.payload.disabled;
          break;
      }
    },
  },
});

export const selectButtonStates = (state: RootState) =>
  state.whereToBox.buttons;

export const { setEnable } = whereToBoxSlice.actions;

export default whereToBoxSlice.reducer;
