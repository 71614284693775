import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {DetailsModelInterface, FilterData} from "interfaces/details";
import { RootState } from "app/store";
import configuration from "components/features/Details/configuration";
import { getCustomerCode } from "app/utils";

interface DetailsState {
    model: DetailsModelInterface;
}

const initialState: DetailsState = {
    model:  configuration[getCustomerCode()].model,
};

export const detailsSlice = createSlice({
    name: "details",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setDetailsModel: (
            state,
            action: PayloadAction<Partial<DetailsModelInterface>>
        ) => {
            state.model = {
                ...state.model,
                ...action.payload
            };
        },
        setFilterData: (
            state,
            action:PayloadAction<{[key: string]: FilterData }>
        ) => {
            const keys = action.payload as any;
            Object.keys(keys).forEach((key) => {
                if(!(key in state.model.filterData)){
                    console.error(`Warning: ${key} is not defined in FilterData interface`)
                }
            })
            state.model.filterData = {
                ...state.model.filterData,
                ...action.payload
            }
        },
        resetDetails: (state) => {
            state.model = initialState.model;
        },
    }
});

export const {
    setDetailsModel,
    resetDetails,
    setFilterData
} = detailsSlice.actions;

export const selectDetailsModel = (state: RootState) => state.details.model;

export default detailsSlice.reducer;
