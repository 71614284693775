import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export interface PageControlInterface {
  url: string;
  sequence: number;
  enabled: boolean;
}

interface PageControlState {
  pages: PageControlInterface[];
}

const initialState: PageControlState = {
  pages: [
    {
      url: "/travel-theme",
      sequence: 0,
      enabled: true,
    },
    {
      url: "/compose-trip",
      sequence: 1,
      enabled: false,
    },
    {
      url: "/passengers",
      sequence: 2,
      enabled: false,
    },
    {
      url: "/summary",
      sequence: 3,
      enabled: false,
    },
    {
      url: "/confirmation",
      sequence: 4,
      enabled: false,
    },
  ],
};

export const pageControlSlice = createSlice({
  name: "pageControl",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNewPage: (state, action: PayloadAction<string>) => {
      const selectedPage = state.pages.find(
        (page) => page.url === action.payload,
      );

      if (!selectedPage) {
        return;
      }

      state.pages.forEach((item, i) => {
        if (selectedPage.url === "/confirmation") {
          state.pages[i].enabled = selectedPage.sequence <= item.sequence;
        } else {
          state.pages[i].enabled = selectedPage.sequence >= item.sequence;
        }
      });
    },
  },
});

export const { setNewPage } = pageControlSlice.actions;

export const selectPages = (state: RootState) => state.pageControl.pages;

export default pageControlSlice.reducer;
