import ReactHtmlParser from "html-react-parser";
import { useEffect, useState } from "react";
import { TextViewProps } from "interfaces/textView";
import { useAppSelector } from "app/hooks";
import useResourceService from "app/useResourceService";
import { selectLanguage } from "slices/language";
import { selectLocalizations } from "slices/resourceService";

const TextView = ({ module, textId, parseHtml }: TextViewProps) => {
  const texts = useAppSelector(selectLocalizations);
  const language = useAppSelector(selectLanguage);
  const [text, setText] = useState("");
  const { getText } = useResourceService();

  useEffect(() => {
    setText(getText(textId, module));
  }, [textId, language, texts]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{parseHtml ? ReactHtmlParser(text) : text}</>;
};

export default TextView;
