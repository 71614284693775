export enum TextModules {
    ERROR = "error",
    TAXCODE = "taxcode",
    FOOTER = "footer",
    GENERAL = "general",
    LABEL = "label",
    OPQ_CONFIRMATION = "opq_confirmation",
    OPQ_DETAILS = "opq_details",
    OPQ_PATH = "opq_path",
    OPQ_PAXS = "opq_paxs",
    OPQ_PAYMENT = "opq_payment",
    OPQ_RETRIEVE = "opq_retrieve",
    OPQ_SHARED = "opq_shared",
    POOLS = "pools",
    SUCCESS = "success",
    WARNING = "warning",
    CITIES = "cities",
    FQTVAIRL = "fqtvairl",
    COUNTRIES = "countries",
}

export interface TextViewProps {
    module?: TextModules;
    textId: string;
    parseHtml?: boolean;
}
