import {
  PaymentConfigurationInterface,
  PaymentModelInterface,
} from "interfaces/payment";

const visaValidation = {
  type: "visa",
  validRegex: "^4",
  possibleRegEx: "(\\d{1,4})",
  maxLength: "19",
  cvvMaxLength: "3",
  luhn: true,
  cvvRequired: true,
};

const masterCardvalidation = {
  type: "mastercard",
  validRegex: "/^(5[1-5]|677189)|^(222[1-9]|2[3-6]d{2}|27[0-1]d|2720)/",
  possibleRegEx: "/(d{1,4})/g",
  maxLength: "16",
  cvvMaxLength: "3",
  luhn: true,
  cvvRequired: true,
};

const airPlusValidation = {
  type: "airplus",
  validRegex: "^1[0-9]{14}$",
  possibleRegEx: "^1",
  maxLength: "15",
  cvvValidRegex: "^[0-9]{3}$",
  cvvMaxLength: "3",
  cvvRequired: false,
};

const amexValidation = {
  type: "visa",
  validRegex: "^4",
  possibleRegEx: "(\\d{1,4})",
  maxLength: "15",
  cvvMaxLength: "3",
  luhn: true,
  cvvRequired: true,
};

const defaultPaymentModel: PaymentModelInterface = {
  conditions: false,
  cc: {
    token: "",
  },
  dd: {
    bankAccountNumber: "",
    bankAccountHolderName: "",
    sepaTermsAgree: false,
  },
  billing: {
    addressData: "",
    city: "",
    addressType: "addressLine",
    companyName: "",
    countryCode: "",
    firstName: "",
    lastName: "",
    postalCode: "",
    taxNumber: "",
    title: "",
  },
  contact: {
    emailAddress: "",
    countryPrefix: "",
    phoneNumber: "",
    phoneType: "mobile",
    setHideFileKey: false,
  },
};

const configuration: PaymentConfigurationInterface = {
  ewg: {
    yourFlights: {
      hasFromPrice: false
    },
    hasOptionalFields: false,
    hasDangerousGoods: false,
    paymentMethods: {
      hasOptionalFields: true,
      ccConfig: {
        ccPaymentCode: "CARD",
        tokenizer: {
          config: {
            pci: true,
            cvv: true,
            debug: true,
            tokenExID: "",
            authenticationKey: "",
            tokenScheme: "",
            origin: "",
            timestamp: "",
            enablePrettyFormat: true,
            inputType: "text", // important because enablePrettyFormat only works with text
            cvvInputType: "tel",
            enableValidateOnBlur: true,
            cvvContainerID: "cvvContainer",
            // styling of the inputs
            // base - basic input styling
            // focus - when input is focused
            // error - when validation error occurs
            styles: {
              base:
                "background: #FFFFFF 0% 0% no-repeat padding-box;" +
                "border: 1px solid #CED4DA;" +
                "border-radius: 8px;" +
                "opacity: 1;" +
                "text-align: left;" +
                'font: normal normal normal 16px/18px "HelveticaNowTextRegular", Helvetica, Arial, sans-serif;' +
                "letter-spacing: 0px;" +
                "color: #212529;" +
                "opacity: 1;" +
                "border: 1px solid #CED4DA;" +
                "border-radius: 8px;" +
                "padding: 9px 0 9px 10px;" +
                "height: 36px;",
              focus: "border: 1px solid #D41370" + ";outline: none;",
              error: "border: 1px solid #BF0000;",
              cvv: {
                base:
                  "background: #FFFFFF 0% 0% no-repeat padding-box;" +
                  "border: 1px solid #CED4DA;" +
                  "border-radius: 8px;" +
                  "opacity: 1;" +
                  "text-align: left;" +
                  "font: normal normal normal 16px/18px HelveticaNowText, sans-serif;" +
                  "letter-spacing: 0px;" +
                  "color: #212529;" +
                  "opacity: 1;" +
                  "border: 1px solid #CED4DA;" +
                  "border-radius: 8px;" +
                  "height:36px;" +
                  "padding: 9px 0 9px 10px;",
                focus: "border: 1px solid #D41370;" + "outline: none;",
                error: "border: 1px solid #BF0000;",
              },
            },
          },
          cardData: {
            CCs: [
              {
                types: [
                  {
                    name: "Visa Credit",
                    code: "VI",
                  },
                  {
                    name: "Visa Debit",
                    code: "VD",
                  },
                  {
                    name: "Visa Electron",
                    code: "VE",
                  },
                  {
                    name: "Eurowings Visa",
                    code: "BV",
                  },
                ],
                cardHolderValidation: {
                  minLength: 4,
                  maxLength: 30,
                },
                cardType: "visa",
                paymentMethod: "VIS",
                validation: visaValidation,
              },
              {
                types: [
                  {
                    name: "Mastercard / Debit Mastercard",
                    code: "MC",
                  },
                ],
                cardHolderValidation: {
                  minLength: 4,
                  maxLength: 30,
                },
                paymentMethod: "ECA",
                cardType: "masterCard",
                validation: masterCardvalidation,
              },
              {
                types: [
                  {
                    name: "American Express",
                    code: "AX",
                  },
                ],
                cardHolderValidation: {
                  minLength: 4,
                  maxLength: 30,
                },
                paymentMethod: "AMX",
                cardType: "americanExpress",
                validation: amexValidation,
              },
              {
                types: [
                  {
                    name: "AirPlus",
                    code: "TP",
                  },
                ],
                cardHolderValidation: {
                  minLength: 14,
                  maxLength: 20,
                },
                paymentMethod: "UAP",
                cardType: "airplus",
                validation: airPlusValidation,
              },
            ],
            tokenExSupportedCards: ["VIS", "ECA", "AMX"],
          },
        },
      },
      ddConfig: {
        ddEnabled: true,
        sepaDaysBeforeEarlyDeparture: 15,
        hasModal: false
      },
      hasPaymentStandards: false
    },
    passengers: {
      hasContactInfo: false
    },
    model: {
      ...defaultPaymentModel,
      cc: {
        expiryYear: "",
        expiryMonth: "",
        cardType: "",
        cardHolder: "",
        token: "",
      },
    },
    billing: {
      hasSalutation: true,
      hasPOBox: false,
      hasBillingDesc: false,
      hasTaxNumber: false,
    },
    hasProvidePhoneText: false,
  },
  lhs: {
    yourFlights: {
      hasFromPrice: false
    },
    hasOptionalFields: true,
    hasDangerousGoods: true,
    paymentMethods: {
      hasOptionalFields: false,
      ddConfig: {
        ddEnabled: true,
        sepaDaysBeforeEarlyDeparture: 8,
        hasModal: true
      },
      ccConfig: {
        ccPaymentCode: "TOKEN",
      },
      hasPaymentStandards: true
    },
    passengers: {
      hasContactInfo: true
    },
    model: defaultPaymentModel,
    billing: {
      hasSalutation: false,
      hasPOBox: true,
      hasBillingDesc: true,
      hasTaxNumber: true
    },
    termsAndConditions: {
      hasCompanyName: true
    },
    hasProvidePhoneText: true
  },
  oss: {
    yourFlights: {
      hasFromPrice: true
    },
    hasOptionalFields: false,
    hasDangerousGoods: false,
    paymentMethods: {
      hasOptionalFields: true,
      ccConfig: {
        ccPaymentCode: "TOKEN",
      },
      hasPaymentStandards: true
    },
    passengers: {
      hasContactInfo: true
    },
    model: defaultPaymentModel,
    billing: {
      hasSalutation: false,
      hasPOBox: false,
      hasTaxNumber: true,
      hasBillingDesc: true,

    },
    termsAndConditions: {
      hasCompanyName: false,
    },
    hasProvidePhoneText: false,
  },
};

export default configuration;
