import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import alertReducer from "slices/alert";
import appReducer from "slices/app";
import confirmationReducer from "slices/confirmation";
import detailsReducer from "slices/details";
import pageControlReducer from "slices/pageControl";
import passengerReducer from "slices/passenger";
import paymentReducer from "slices/payment";
import poolsReducer from "slices/pools";
import retrieveReducer from "slices/retrieve";
import whereToBoxReducer from "slices/whereToBox";
import resourceServiceReducer from "slices/resourceService"
import languageReducer from "slices/language"

const retrievePersistConfig = {
  key: "retrieve",
  storage,
};

const detailsPersistConfig = {
  key: "details",
  storage,
};

const pageControlPersistConfig = {
  key: "pageControl",
  storage,
};

const passengerPersistConfig = {
  key: "passengers",
  storage,
};

const paymentPersistConfig = {
  key: "payment",
  storage,
};

const confirmationPersistConfig = {
  key: "confirmation",
  storage,
};

const languagePersistConfig = {
  key: "language",
  storage
}

const persistedRetrieveReducer = persistReducer(
  retrievePersistConfig,
  retrieveReducer,
);
const persistedDetailsReducer = persistReducer(
  detailsPersistConfig,
  detailsReducer,
);
const persistedPageControlReducer = persistReducer(
  pageControlPersistConfig,
  pageControlReducer,
);
const persistedPassengerReducer = persistReducer(
  passengerPersistConfig,
  passengerReducer,
);
const persistedPaymentReducer = persistReducer(
  paymentPersistConfig,
  paymentReducer,
);
const persistedConfirmationReducer = persistReducer(
  confirmationPersistConfig,
  confirmationReducer,
);
const persistedLanguageReducer = persistReducer(
    languagePersistConfig,
    languageReducer
);

export const store = configureStore({
  reducer: {
    retrieve: persistedRetrieveReducer,
    details: persistedDetailsReducer,
    pageControl: persistedPageControlReducer,
    passenger: persistedPassengerReducer,
    payment: persistedPaymentReducer,
    confirmation: persistedConfirmationReducer,
    pools: poolsReducer,
    alert: alertReducer,
    app: appReducer,
    whereToBox: whereToBoxReducer,
    resourceService: resourceServiceReducer,
    language: persistedLanguageReducer
  },
  middleware: [],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
